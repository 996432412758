.rotating-dashed {
  position: relative;
  overflow: hidden;
  color: #92192a;
}

.selected{
  color: #e74c3c;
}

.rotating-dashed .dashing {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}
.rotating-dashed .dashing:nth-of-type(1) {
  transform: rotate(0deg);
}
.rotating-dashed .dashing:nth-of-type(2) {
  transform: rotate(90deg);
}
.rotating-dashed .dashing:nth-of-type(3) {
  transform: rotate(180deg);
}
.rotating-dashed .dashing:nth-of-type(4) {
  transform: rotate(270deg);
}
.rotating-dashed .dashing i {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  border-bottom: 5px dashed;
  animation: slideDash 2.5s infinite linear;
}
.rotating-dashed strong {
  display: block;
  width: 95px;
  line-height: 90px;
  text-align: center;
  position: absolute;
  font-size: 50px;
  transform: rotate(90deg);
}

@keyframes slideDash {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}
