@font-face {
  font-family: 'Vazir';
  src: url('./Vazir-Medium.eot') format('eot'),
  url('./Vazir-Medium.woff') format('woff'),
  url('./Vazir-Medium.ttf') format('truetype');
  font-style:normal;
  font-weight:normal;
}
html, body {
  width: 100%;
  height: 95%;
  font: 14px "Vazir", Futura, sans-serif;
  /* background-color: black; */
  background-image: url('../../public/vcat.jpg') !important;
  background-size: cover !important;
  color: #e0e0e0;
  overflow-y: hidden;
  overflow-x: hidden;
  user-select: none;
}



h1 {
  color: white;
}

#root {
  width: 100%;
  height: 100%;
}

#container canvas, .overlay {
  position: absolute;
}

.button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 80px;
  border: 3px dashed white;
  background-color: Transparent;
  text-decoration: none;
  color: white;
  font-size: 18px;
  margin: 20px 20px 0px 20px;
  vertical-align: center;
}

.button:hover {
  border-color: #e74c3c;
  color: #e74c3c;
}

.overlay {
  left: 50%;
  top: 50%;
}

.withinOverlay {
  position: relative;
  left: -50%;
  margin-top: -25%;
}

.board {
  position: relative;
  margin-top: -40%;
}

.border {
  width: 400px;
  padding: 30px;
  position: relative;
  padding-bottom: 50px;
  border: solid 5px white;
}

.border:before {
  background: none;
  border: 5px solid white;
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  right: -20px;
  bottom: -20px;
  pointer-events: none;
}

ol, ul {
  padding-left: 30px;
}

.white{
  color: #e0e0e0;
}

.blue{
  color: #92192a;
}

.red{
  color: #e74c3c;
}


.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
  width: 300px;
}

.marks{
  margin:5px;
  margin-top: 5px;
}

.square {
  background: transparent;
  border: 2px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 160px;
  width: 160px;
  margin-right: -1px;
  margin-top: -1px;
  border-color: #92192a;
  user-select: none;
}

.classical{
  font-size: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.adjustCenter{
  margin-top: 20px;
  margin-left: 30px;
}

.collapseChoice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px;
  border-color: #e74c3c;
  text-align: center;
  cursor: default;
  border-style: solid;
  margin: 5px;
  user-select: none;
}

.collapseChoice:hover{
  background-color: #e74c3c;
}

.xScore{
  margin-top: 10px;
  font-size: 20px;
  float: left;
}
.yScore{
  margin-top: 10px;
  font-size: 20px;
  float: right;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
}

.game-board {
  width: 500px;
}

.game-info {
  margin-left: 20px;
  top: 0px;
}
